import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { Product } from "./types";

interface AddToCartDialogProps {
  open: boolean;
  onClose: () => void;
  addToCart: (product: Product, category: string) => void;
  selectedProduct: Product | null;
  category: string; // Assume category is passed as a prop
}

const AddToCartDialog: React.FC<AddToCartDialogProps> = ({
  open,
  onClose,
  addToCart,
  selectedProduct,
  category,
}) => {
  const [quantity, setQuantity] = useState<number>(1);
  const [description, setDescription] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<string>("");

  useEffect(() => {
    if (open && selectedProduct) {
      setQuantity(1);
      setDescription("");
    }
  }, [open, selectedProduct]);

  const handleAddToCart = () => {
    if (description.trim() === "") {
      setDescriptionError("Description is required");
      return;
    }

    setDescriptionError(""); // Clear the error if description is provided

    const newItem: Product = {
      ...selectedProduct!,
      quantity: quantity,
      description: description,
    };

    addToCart(newItem, category); // Pass both the newItem and category to addToCart
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add to Cart</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{selectedProduct?.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              ${selectedProduct?.price.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Quantity"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
              inputProps={{
                min: 1,
                step: 1,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              error={Boolean(descriptionError)}
              helperText={descriptionError}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAddToCart} color="primary" variant="contained">
          Add to Cart
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToCartDialog;
