import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { Product } from "./types";

interface OrderSummaryPopupProps {
  cart: Product[];
  onClose: () => void;
  clearCart: () => void;
}

const OrderSummaryPopup: React.FC<OrderSummaryPopupProps> = ({
  cart,
  onClose,
  clearCart,
}) => {
  const handleClose = () => {
    clearCart();
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Order Summary</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
          {cart.map((product) => (
            <Grid item xs={12} md={6} lg={4} key={product.id}>
              <Card style={{ marginBottom: "20px" }}>
                <CardContent>
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography variant="body1" color="text.primary">
                    {product.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Price: ${product.price}/lb
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Quantity: {product.quantity}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Category: {product.category}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="body1"
          style={{
            marginTop: "20px",
            textAlign: "center",
            fontStyle: "italic",
            color: "#fff",
            backgroundColor: "#385d31",
            padding: "10px",
            borderRadius: "5px",
            fontSize: "1.2rem",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Text shadow
          }}
        >
          Your order has been received. Please check your email for a
          confirmation message!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderSummaryPopup;
