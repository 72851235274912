import React, { useState, useEffect } from "react";
import Cart from "./Cart";
import CheckoutDialog from "./CheckoutDialog";
import OrderSummaryPopup from "./OrderSummaryPopup"; // Import the OrderSummaryPopup component
import { Product, CustomerInfo } from "./types";

const CartPage: React.FC<{ updateCartCount: () => void }> = ({
  updateCartCount,
}) => {
  const [cart, setCart] = useState<Product[]>([]);
  const [showCheckoutDialog, setShowCheckoutDialog] = useState<boolean>(false);
  const [showOrderSummaryPopup, setShowOrderSummaryPopup] =
    useState<boolean>(false);

  useEffect(() => {
    const cartData = localStorage.getItem("cart");
    if (cartData) {
      setCart(JSON.parse(cartData));
    }
  }, []);

  const saveCartToLocalStorage = (cartData: Product[]) => {
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  const removeFromCart = (productId: number) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart);
    updateCartCount();
  };

  const handleCheckout = () => {
    setShowCheckoutDialog(true);
  };

  const handleCloseCheckoutDialog = () => {
    setShowCheckoutDialog(false);
  };

  const handleCloseOrderSummaryPopup = () => {
    setShowOrderSummaryPopup(false);
  };

  const handleSubmitCheckout = (customerInfo: CustomerInfo) => {
    console.log("Submitted customer info:", customerInfo);
    // Perform further actions like order submission, etc.
    setShowCheckoutDialog(false);
    setShowOrderSummaryPopup(true);
  };

  const clearCart = () => {
    localStorage.removeItem("cart");
    setCart([]);
    updateCartCount();
  };

  return (
    <div
      style={{
        marginTop: "120px",
        marginBottom: "120px",
        padding: "20px",
      }}
    >
      <h1
        style={{
          fontSize: "2.5rem",
          color: "#385d31",
          textAlign: "center",
        }}
      >
        Shopping Cart
      </h1>
      <Cart cart={cart} removeFromCart={removeFromCart} />
      <button
        onClick={handleCheckout}
        style={{
          backgroundColor: "#385d31",
          border: "none",
          color: "white",
          padding: "15px 32px",
          textAlign: "center",
          textDecoration: "none",
          display: "inline-block",
          fontSize: "16px",
          margin: "4px 2px",
          cursor: "pointer",
          borderRadius: "12px",
        }}
      >
        Checkout
      </button>
      {showCheckoutDialog && (
        <CheckoutDialog
          open={showCheckoutDialog}
          cart={cart}
          onSubmit={handleSubmitCheckout}
          onClose={handleCloseCheckoutDialog}
        />
      )}
      {showOrderSummaryPopup && (
        <OrderSummaryPopup
          cart={cart}
          onClose={handleCloseOrderSummaryPopup}
          clearCart={clearCart} // Pass the clearCart function to the OrderSummaryPopup component
        />
      )}
    </div>
  );
};

export default CartPage;
