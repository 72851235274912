import axios from "axios";

interface EmailData {
  to: string;
  subject: string;
  message: string;
}

export const sendEmail = async (data: EmailData): Promise<boolean> => {
  try {
    console.log(data);
    await axios.post(
      "https://i4izugswm6.execute-api.us-east-2.amazonaws.com/prod/send-email",
      data
    );
    return true; // Email sent successfully
  } catch (error) {
    console.error("Error sending email:", (error as Error).message);
    return false; // Email sending failed
  }
};
