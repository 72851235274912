import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { CustomerInfo, Product } from "./types";
import { sendEmail } from "./emailService"; // Import the sendEmail function

interface CheckoutDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (customerInfo: CustomerInfo) => void;
  cart: Product[];
}

const CheckoutDialog: React.FC<CheckoutDialogProps> = ({
  open,
  onClose,
  onSubmit,
  cart,
}) => {
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState<Partial<CustomerInfo>>({});

  const handleSubmit = async () => {
    const formIsValid = validateForm();

    if (formIsValid) {
      onSubmit(customerInfo);
      // After submission, send email
      const emailData = {
        to: customerInfo.email,
        subject: "Order Summary",
        message: generateOrderSummary(customerInfo, cart), // Pass customerInfo here
      };
      await sendEmail(emailData); // Send email
    }
  };

  const validateForm = (): boolean => {
    const validationErrors: Partial<CustomerInfo> = {};

    if (!customerInfo.firstName.trim()) {
      validationErrors.firstName = "First name is required";
    }
    if (!customerInfo.lastName.trim()) {
      validationErrors.lastName = "Last name is required";
    }
    if (!isValidEmail(customerInfo.email)) {
      validationErrors.email = "Please enter a valid email address";
    }
    if (!isValidPhoneNumber(customerInfo.phoneNumber)) {
      validationErrors.phoneNumber = "Please enter a 10-digit phone number";
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const isValidEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidPhoneNumber = (phoneNumber: string): boolean => {
    return /^\d{10}$/.test(phoneNumber);
  };

  const generateOrderSummary = (
    customerInfo: CustomerInfo,
    cart: Product[]
  ): string => {
    // Generate order summary based on the cart and customer info
    let summary = `
      <h1>Hi ${customerInfo.firstName} ${customerInfo.lastName},</h1>
      <p>Thank you for shopping with us. We are working on your order and look forward to seeing you soon!</p>
      <p>Your order will be ready in 30-40 minutes.</p>
      <h2>Order Summary:</h2>
      <table style="width: 100%; border-collapse: collapse;">
          <thead>
              <tr>
                  <th style="border: 1px solid #ddd; padding: 8px;">Product</th>
                  <th style="border: 1px solid #ddd; padding: 8px;">Price</th>
                  <th style="border: 1px solid #ddd; padding: 8px;">Quantity</th>
                  <th style="border: 1px solid #ddd; padding: 8px;">Description</th>
              </tr>
          </thead>
          <tbody>
  `;

    cart.forEach((product) => {
      const productName = `${product.category} - ${product.name}`; // Concatenate category and name
      summary += `
          <tr>
              <td style="border: 1px solid #ddd; padding: 8px;">${productName}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">$${product.price}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${product.quantity}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${product.description}</td>
          </tr>
      `;
    });

    summary += `
          </tbody>
      </table>
      <p>Thank you!</p>
      <p>Customer phone number: ${customerInfo.phoneNumber}</p>
    `;

    return summary;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Checkout</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        {cart.length === 0 ? (
          <Typography variant="body1">Your cart is empty.</Typography>
        ) : (
          <>
            <Grid container spacing={2}>
              {cart.map((product) => (
                <Grid item xs={12} md={6} lg={4} key={product.id}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent>
                      <img
                        src={product.imageUrl}
                        alt={product.name}
                        style={{
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      />
                      <Typography variant="body1" color="text.primary">
                        {product.category} - {product.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Price: ${product.price}/lb
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Quantity: {product.quantity}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <TextField
              label="First Name *"
              value={customerInfo.firstName}
              onChange={(e) =>
                setCustomerInfo((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                }))
              }
              fullWidth
              required
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
            <TextField
              label="Last Name *"
              value={customerInfo.lastName}
              onChange={(e) =>
                setCustomerInfo((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                }))
              }
              fullWidth
              required
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
            <TextField
              label="Email *"
              value={customerInfo.email}
              onChange={(e) =>
                setCustomerInfo((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
              fullWidth
              required
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              label="Phone Number *"
              value={customerInfo.phoneNumber}
              onChange={(e) =>
                setCustomerInfo((prevState) => ({
                  ...prevState,
                  phoneNumber: e.target.value,
                }))
              }
              fullWidth
              required
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={cart.length === 0}
        >
          Reserve & Pay in store!
        </Button>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutDialog;
