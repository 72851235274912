import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffff",
    },
  },
});

const navItems = ["Home", "About", "Contact"];

export default function DrawerAppBar({ cartCount }: { cartCount: number }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const prevScrollY = useRef(0);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const isScrollingUp = scrollTop < prevScrollY.current;
      prevScrollY.current = scrollTop;

      const showThreshold = 50;

      setIsNavbarVisible(isScrollingUp || scrollTop < showThreshold);
      setShowBackToTop(scrollTop > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawerBackground = "#fff";

  const drawerTextStyle = {
    color: "#385d31",
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <AppBar style={{ display: isNavbarVisible ? "block" : "none" }}>
          <Toolbar>
            <Link to="/">
              <img
                src={require("../assets/images/logo.png")}
                alt="Logo"
                style={{
                  width: "40%",
                  cursor: "pointer",
                  marginLeft: "-30px",
                }}
              />
            </Link>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <MenuIcon
                  onClick={toggleDrawer}
                  sx={{ color: "#385d31", marginRight: "10px" }}
                />
                <Link to="/cartpage" style={{ textDecoration: "none" }}>
                  <Button style={{ color: "#385d31" }}>
                    <ShoppingCartIcon />
                    <span style={{ marginLeft: "5px" }}>{cartCount}</span>
                  </Button>
                </Link>
              </Box>
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                sx={{ "& .MuiDrawer-paper": { background: drawerBackground } }}
              >
                <Box
                  sx={{
                    width: 250,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: "100%",
                    padding: "20px",
                    position: "relative", // Necessary for absolute positioning
                  }}
                  role="presentation"
                  onClick={toggleDrawer}
                  onKeyDown={toggleDrawer}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                    }}
                  >
                    <Link to="/cartpage" style={{ textDecoration: "none" }}>
                      <Button style={{ color: "#385d31" }}>
                        <ShoppingCartIcon />
                        <span style={{ marginLeft: "5px" }}>{cartCount}</span>
                      </Button>
                    </Link>
                  </Box>
                  <Link to="/">
                    <img
                      src={require("../assets/images/logo.png")}
                      alt="Logo"
                      style={{ width: "100%" }}
                    />
                  </Link>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <CloseIcon
                      onClick={toggleDrawer}
                      sx={{
                        cursor: "pointer",
                        color: "385d31",
                      }}
                    />
                  </Box>
                  <List
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "-60%",
                    }}
                  >
                    {navItems.map((item) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        key={item}
                        to={`/${item.toLowerCase()}`}
                      >
                        <ListItem button key={item}>
                          <ListItemText
                            primary={item}
                            primaryTypographyProps={{
                              style: drawerTextStyle,
                            }}
                          />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Box>
              </Drawer>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                <Box>
                  {navItems.map((item) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      key={item}
                      to={`/${item.toLowerCase()}`}
                    >
                      <Button
                        key={item}
                        style={{
                          color: "#385d31",
                          marginRight: "10px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {item}
                      </Button>
                    </Link>
                  ))}
                  <Link to="/cartpage">
                    <Button style={{ color: "#385d31" }}>
                      <ShoppingCartIcon />
                      <span style={{ marginLeft: "5px" }}>{cartCount}</span>
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box>
          <Toolbar />
        </Box>
        {showBackToTop && (
          <Box
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "10%",
              transform: "translateX(-50%)",
              cursor: "pointer",
              zIndex: 1000,
            }}
          >
            <KeyboardArrowUpIcon
              onClick={scrollToTop}
              fontSize="large"
              style={{ color: "#f1975b" }}
              sx={{ fontSize: "6rem" }}
            />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
