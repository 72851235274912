import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import AddToCartDialog from "./AddToCartDialog";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Product } from "./types";
import { v4 as uuidv4 } from "uuid";

interface HomeProps {
  updateCartCount: (count: number) => void;
}

interface SelectedProduct extends Product {
  category: string;
}

function Home({ updateCartCount }: HomeProps) {
  const [categories, setCategories] = useState<Product[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] =
    useState<SelectedProduct | null>(null);

  useEffect(() => {
    fetchCategories();
    updateCartCount(getCartCount());
  }, [updateCartCount]);

  const fetchCategories = async () => {
    try {
      const response = await fetch("../products.json");
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const addToCart = (product: Product, category: string) => {
    try {
      const newProduct = { ...product, id: uuidv4(), category: category };

      const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
      const updatedCartData = [...cartData, newProduct];

      localStorage.setItem("cart", JSON.stringify(updatedCartData));

      const newCartCount = updatedCartData.length;
      updateCartCount(newCartCount);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const getCartCount = () => {
    const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
    return cartData.length;
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    appendDots: (dots: any) => (
      <div style={{ paddingTop: "20px" }}>
        <ul style={{ marginBottom: "-20px" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const containerStyle: React.CSSProperties = {
    textAlign: "center",
    marginTop: "120px",
    marginBottom: "50px",
    maxWidth: "960px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0 15px",
  };

  const buttonStyle: React.CSSProperties = {
    marginBottom: "30px",
    height: "auto",
    backgroundColor: "#385d31",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    transition: "background-color 0.3s ease",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "16px",
    width: "auto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const cardStyle: React.CSSProperties = {
    maxWidth: "200px",
    height: "350px",
    margin: "0 auto",
    marginBottom: "20px",
  };

  const cardImageStyle: React.CSSProperties = {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "150px",
  };

  const renderProductSlider = (category: string, items: Product[]) => {
    return (
      <div key={category} style={{ marginBottom: "50px" }}>
        <h2
          style={{ fontSize: "1.5rem", color: "#385d31", marginBottom: "20px" }}
        >
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </h2>
        <Slider {...sliderSettings}>
          {items.map((product) => (
            <div key={product.id}>
              <Card style={cardStyle}>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      style={cardImageStyle}
                    />
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ marginTop: "10px" }}
                    >
                      {category} {product.name}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      ${product.price.toFixed(2)}/lb
                    </Typography>
                    {/* Check if the product is the first or fifth product in the "Chicken" category */}
                    {category === "Chicken" &&
                      (product.id === 1 || product.id === 2) && (
                        <Typography variant="body2" color="text.secondary">
                          +$0.50 to Cut & Clean
                        </Typography>
                      )}
                  </div>
                  <div style={{ textAlign: "center", marginTop: "auto" }}>
                    <Button
                      style={buttonStyle}
                      onClick={() => {
                        setSelectedProduct({ ...product, category });
                        setOpenDialog(true);
                      }}
                    >
                      Add to Cart
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: "2.5rem", color: "#385d31" }}>
        Welcome to Our Market
      </h1>
      <p style={{ fontSize: "1.2rem", color: "#000", marginBottom: "30px" }}>
        Explore our departments and discover quality products.
      </p>

      {categories.map((category) =>
        renderProductSlider(category.category, category.items)
      )}

      {selectedProduct && (
        <AddToCartDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          addToCart={addToCart}
          selectedProduct={selectedProduct}
          category={selectedProduct.category}
        />
      )}
      <br />
      <p style={{ fontSize: "1.2rem", color: "#000", marginBottom: "30px" }}>
        If you have any questions or concerns, please don't hesitate to{" "}
        <a
          href="/contact"
          style={{ color: "#385d31", textDecoration: "underline" }}
        >
          contact us
        </a>
        .
      </p>
    </div>
  );
}

export default Home;
