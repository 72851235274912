import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AboutUs() {
  const sliderRef = useRef(null); // Create a ref for the Slider component

  const images = [
    require("../assets/images/almadina.png"),
    require("../assets/images/1.png"),
    require("../assets/images/2.png"),
    // Add more image paths as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "0",
  };

  const headingStyle: React.CSSProperties = {
    fontSize: "2.5rem",
    color: "#385d31",
    textAlign: "center",
  };

  return (
    <div style={{ marginTop: "8%", textAlign: "center", padding: "20px" }}>
      <h1 style={headingStyle}>About Us</h1>
      <div style={{ margin: "0 auto", maxWidth: "80%", borderRadius: "10px" }}>
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div key={index} style={{ textAlign: "center" }}>
              <img
                src={image}
                alt={`Slide ${index + 1}`} // Updated alt attribute
                style={{ width: "100%", height: "auto", maxHeight: "400px" }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div style={{ marginTop: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <p style={{ fontSize: "1.2rem", color: "#666", lineHeight: "1.6" }}>
          Welcome to Almadina International Halal Foods! We pride ourselves on
          offering a diverse range of high-quality products and exceptional
          services to our valued customers. Our market is dedicated to providing
          fresh, delicious, and halal-certified foods to meet your culinary
          needs.
        </p>
        <p style={{ fontSize: "1.2rem", color: "#666", lineHeight: "1.6" }}>
          From premium meats and poultry to a wide selection of fresh produce
          and pantry essentials, we strive to be your one-stop shop for all your
          grocery needs. Our commitment to quality and customer satisfaction is
          reflected in everything we do.
        </p>
        <p style={{ fontSize: "1.2rem", color: "#666", lineHeight: "1.6" }}>
          At Almadina International Halal Foods, we believe in fostering a
          welcoming and inclusive environment where our customers feel valued
          and respected. Whether you're planning a special meal or stocking up
          on everyday essentials, we're here to help you make the best choices
          for you and your family.
        </p>
        <p style={{ fontSize: "1.2rem", color: "#666", lineHeight: "1.6" }}>
          Come visit us today and experience the difference at Almadina
          International Halal Foods. We look forward to serving you and
          providing you with an exceptional shopping experience.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
