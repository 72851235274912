import React, { useState, useEffect } from "react";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import CartPage from "./components/CartPage";
import Homepage from "./components/Home";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

// Styled component for the message container
const MessageContainer = styled.div`
  text-align: center;
  padding: 2rem;
  font-size: 2rem;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${require("./assets/images/1.png")}); // Use your background image
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
`;

// Styled component for the message text
const MessageText = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  text-align: center;

  span {
    color: #385d31;
    font-weight: bold;
    font-size: 2rem;
  }

  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #f1975b;
    font-weight: bold;

  }
`;

// Styled component for the logo
const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 90px;
  }
`;

function App() {
  const [cartCount, setCartCount] = useState<number>(0);
  const [isMaintenanceMode] = useState<boolean>(false); // Set to true for maintenance mode

  useEffect(() => {
    fetchCartCount();
  }, []);

  const fetchCartCount = () => {
    try {
      // Get cart data from local storage
      const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
      const count = cartData.length;
      setCartCount(count);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const updateCartCount = () => {
    fetchCartCount();
  };

  return (
    <div>
      {/* Conditionally render the Maintenance page or the main content */}
      {isMaintenanceMode ? (
        <MessageContainer>
          <Logo src={require("./assets/images/logo.png")} alt="Logo" />
          <MessageText>
            <span>Website Unavailable</span>
            <p>Please contact the store directly!!</p>
          </MessageText>
        </MessageContainer>
      ) : (
        <>
          <NavBar cartCount={cartCount} />
          <Routes>
            <Route
              path="/"
              element={<Homepage updateCartCount={updateCartCount} />}
            />
            <Route
              path="/home"
              element={<Homepage updateCartCount={updateCartCount} />}
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route
              path="/cartpage"
              element={<CartPage updateCartCount={updateCartCount} />}
            />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
