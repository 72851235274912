import React from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Product } from "./types";

interface CartProps {
  cart: Product[];
  removeFromCart: (productId: number) => void;
}

const Cart: React.FC<CartProps> = ({ cart, removeFromCart }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {cart.length === 0 ? (
        <Typography variant="h6" style={{ textAlign: "center" }}>
          Your cart is empty.
        </Typography>
      ) : (
        cart.map((product) => (
          <Card
            key={product.id}
            style={{
              margin: "10px",
              width: "250px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardMedia
              component="img"
              src={product.imageUrl}
              alt={product.name}
              style={{ maxWidth: "100px", margin: "auto", marginTop: "10px" }}
            />
            <CardContent style={{ flexGrow: 1 }}>
              <Typography variant="h6">
                {product.category} {product.name}
              </Typography>
              <Typography variant="body2">
                Description: {product.description}
              </Typography>
              <Typography variant="body2">
                Quantity: {product.quantity}
              </Typography>
              <Typography variant="body2">
                Price: ${product.price.toFixed(2)}
              </Typography>
            </CardContent>
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => removeFromCart(product.id)}
              >
                Remove
              </Button>
            </div>
          </Card>
        ))
      )}
    </div>
  );
};

export default Cart;
