import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Section = styled.section`
  padding: 50px 0;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #385d31;
  text-align: center;
  margin-bottom: 30px;
`;

const ContactInfo = styled.div`
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two cards per row on larger screens */
  }
`;

const Card = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: #385d31;
  margin-bottom: 10px;
`;

const CardContent = styled.p`
  font-size: 1rem;
  color: #666;
`;

const FacebookLink = styled.a`
  display: inline-block;
  color: #385d31;
  text-decoration: none;
`;

const FacebookIcon = styled.svg`
  width: 40px;
  height: auto;
  margin-bottom: 10px;
  fill: green;
`;

const MapContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const MapFrame = styled.iframe`
  width: 100%;
  height: 400px;
  border: 0;
  border-radius: 10px;
`;

const Address = styled.p`
  margin-top: 30px;
  text-align: center;
  font-size: 1.2rem;
  color: #666;
`;

const ContactUs = () => {
  const handlePhoneClick = () => {
    window.location.href = "tel:+13176786040";
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <Container>
        <Section>
          <Title>Contact Us</Title>
          <ContactInfo>
            <Card>
              <CardTitle>Phone</CardTitle>
              <CardContent
                onClick={handlePhoneClick}
                style={{ cursor: "pointer" }}
              >
                (317) 678-6040
              </CardContent>
            </Card>
            <Card>
              <CardTitle>Email</CardTitle>
              <CardContent>
                <a href="mailto:almadinafood2024@gmail.com">
                  almadinafood2024@gmail.com
                </a>
              </CardContent>
            </Card>
            <Card>
              <CardTitle>Hours</CardTitle>
              <CardContent>Mon-Sun: 10am - 9pm</CardContent>
            </Card>
            <Card>
              <CardContent>
                <FacebookLink href="https://www.facebook.com/almadinainternationalfoods">
                  <FacebookIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0C5.373 0 0 5.373 0 12c0 5.992 4.372 10.961 10.125 11.888v-8.389H7.063V12h3.062V9.48c0-3.004 1.79-4.668 4.532-4.668 1.313 0 2.758.235 2.758.235v3.03h-1.555c-1.526 0-2.002.948-2.002 1.922V12h3.406l-.547 3.5h-2.86v8.388C19.628 22.961 24 17.992 24 12c0-6.627-5.373-12-12-12"></path>
                  </FacebookIcon>
                </FacebookLink>
              </CardContent>
            </Card>
          </ContactInfo>
        </Section>
      </Container>
      <Section>
        <MapContainer>
          <MapFrame
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.926477185736!2d-117.20325468492352!3d32.747367688688476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dbf8243c54ad03%3A0xd3471deab26e46bc!2sAlmadina%20International%20Halal%20Foods!5e0!3m2!1sen!2sus!4v1646487156473!5m2!1sen!2sus"
            allowFullScreen
            loading="lazy"
          ></MapFrame>
        </MapContainer>
        <Address>
          Almadina International Halal Foods <br />
          Address: 14029 Mundy Drive Fishers, IN 46038 USA
        </Address>
      </Section>
    </div>
  );
};

export default ContactUs;
